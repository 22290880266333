/* eslint-disable */
import React, {useEffect,useState} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useMsal } from "@azure/msal-react";
import Backdrop from '@mui/material/Backdrop';
import SpinningLoader from '../components/SpinningLoader';
import { Grid, Autocomplete, TextField } from '@mui/material';
import {useNavigate } from 'react-router-dom';
import FormControl from '@mui/material/FormControl';
import appConfig from '../config/appConfig';
import * as Styled from '../components/styled-components/Campaign';
import * as signInActions from '../store/actions/auth'

const TenantSelection = ({ authDetails,getClientInfo }) => {
    const { instance } = useMsal();
    const tenantInfo  = JSON.parse(window.sessionStorage.getItem("tenantInfo"));
    const tenantDetails = JSON.parse(window.sessionStorage.getItem("home_account"));
    const [enableTenantlogin,setEnableTenantlogin] = useState(false);
    const handleTenant = (ten) =>{
        setEnableTenantlogin(true);
        window.sessionStorage.setItem("tenantID",ten);
    }
    const navigate = useNavigate();


    const TenantList = tenantInfo?.tenant.map((ten)=>{
        return {
            id:ten,
            label:ten
         }
    })


    const handleLogin = () => {
        getClientInfo(instance);
    }

    const routeUser = (userDetails) => {
        if (userDetails) {
            window.location.href='/dashboard';
         }
        }

        useEffect(() => {
            const { loginDetails } = authDetails;
            if (authDetails.isLoginSuccessfull) {
              routeUser(loginDetails);
            }
          }, [JSON.stringify(authDetails.loginDetails)])


    const dropDownlistCss = { mt: 1, mb: 1, borderRadius: "5px", border: `1px solid ${appConfig.colors.darkblue}`, background: "#fcfcfb", width: "60%", '.MuiAutocomplete-input': { height: "5px" } };


    return (
        <Grid item xs={12} sm={12} xl={12} lg={12} md={12} style={{ minHeight: "95vh", display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Backdrop
        sx={{ color: '#fff', zIndex: 1000 }}
        open={authDetails.isLoading}
      >
        <SpinningLoader circleColor={appConfig.colors.white} label="Agency Loading" />
      </Backdrop>
        <div >
            <h2><b>Hello, {tenantDetails  ? `${tenantDetails?.idTokenClaims?.firstname.charAt(0).toUpperCase() + tenantDetails?.idTokenClaims?.firstname.slice(1)} ${tenantDetails?.idTokenClaims?.lastname.charAt(0).toUpperCase() + tenantDetails?.idTokenClaims?.lastname.slice(1)}` : "User"}</b></h2>
            <p>Welcome to FALCON! To Login, Please select any one of the agencies.</p>
            <div>
                <FormControl sx={dropDownlistCss}>
                    <Autocomplete
                        disablePortal
                        disableClearable
                        openOnFocus
                        clearOnBlur
                        className='autocomplete-class'
                        defaultValue="Select Agency"
                        options={TenantList}
                        renderInput={(params) => {
                            const newParams = { ...params, inputProps: { ...params.inputProps, "aria-label": "tenant" } }
                            return <TextField {...newParams} />
                        }}
                    onChange={ (e,val) => {handleTenant(val.id)}}
                    />
                </FormControl>
            </div>
            <div aria-labelledby='login' style={{ marginTop: 10 }}>
                <Styled.CampaignButton
                    className='text-trans-none'
                    variant="contained"
                    style={{ width: 100 }}
                    disabled={!enableTenantlogin}
                    onClick={() => { handleLogin() }}
                >
                    Log In
                </Styled.CampaignButton>
            </div>
        </div>
    </Grid>
    )

}



TenantSelection.propTypes = {
    getClientInfo: PropTypes.func.isRequired,
    authDetails: PropTypes.object.isRequired
}

const mapStateToProps = (state) => ({ authDetails: state.auth })

const mapDispatchToProps = (dispatch) => {
    return {
        getClientInfo: (msal) => dispatch(signInActions.getClientInfo(msal)),
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(TenantSelection);


