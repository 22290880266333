import React, { useState,useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Grid} from '@mui/material';
import { endOfDay, startOfDay } from 'date-fns'
import moment from 'moment';

import SkeletonGridLoader from '../components/SkeletonGridLoader';

import DefaultTable from '../components/Table';

import * as auditActions from '../store/actions/audits';
import * as menuActions from '../store/actions/breadcrumb';
import { CalculateUTCTzToUserTz,CalculateUserTzToUTCTz, splitDate, formatDTADate, splitTime } from '../components/Utils';


const Audits = ({auditData,fetchAuditList,setBreadcrumb}) => {

    const [pagination, setPagination] = useState({ limit: 10, offset: 1 });
    const [selectedDate, setSelectedDate] = useState({
      startDate: startOfDay(new Date()),
      endDate: endOfDay(new Date())
  });
  const [channel, setChannel] = useState({key:"Audit Logs", value:"audit"});
  const [auditListData, setAuditListData] = useState([]);

  const [searchText, setSearchText] = useState("");


  
    const handlePagination = (limit, offset) => {
        setPagination({ limit, offset })
      }

      const onDateChange = (date) => {
        setSelectedDate({
            startDate: date?.[0]?.startDate || date?.startDate,
            endDate: date?.[0]?.endDate || date?.endDate
        });
    }

    const handleFilterChange = (filter) => {
      setChannel(filter);
  }

  const handleSearch = (search) => {
    setSearchText(search);
}

    const filterValues = {
      filterBy:channel.value,
      startDate: CalculateUserTzToUTCTz(selectedDate?.startDate).toISOString(),
      endDate: CalculateUserTzToUTCTz(endOfDay(selectedDate?.endDate)).toISOString()
  }

    useEffect(()=>{
      setBreadcrumb('Audit Trail')
      if(auditData?.audits?.data?.data?.length)
        fetchAuditList(filterValues,searchText,pagination.limit, pagination.offset);
    },[])

      useEffect(() => {
        fetchAuditList(filterValues,searchText,pagination.limit, pagination.offset);
      }, [ JSON.stringify(pagination),JSON.stringify(channel),JSON.stringify(selectedDate),searchText])

      useEffect(() => {
        setAuditListData(auditData?.audits?.data?.data?.map((item)=>{
        const data = item;
        data.createdTime = `${formatDTADate(splitDate(CalculateUTCTzToUserTz(item.createdTime)))} ${splitTime(CalculateUTCTzToUserTz(item.createdTime))}`;
        data.url = `[${item.method}] ${item.apiCall}`
        return data;
        }));
    }, [JSON.stringify(auditData?.audits)])

    const handleSort = (sortValue) => {
      console.log("sort",sortValue)
      }

    return <div>

        <Grid container spacing={2}>
        <Grid item xs={6} sm={6} xl={6} lg={6} md={6}>
        <h1 className='page-heading' ><b>Audit Trail</b></h1>
          </Grid>
        <Grid item xs={12} sm={12} xl={12} lg={12} md={12}>
        <hr className='blue-border'/>

        {auditData?.audits?.isLoading ? <SkeletonGridLoader /> :
        <DefaultTable
          title="Total"
          handlePagination={handlePagination}
          // headers={['IP', 'Email','First Name','Last Name', 'URL', 'Accessed Time']}
          headers={[{name:'IP', sortEnabled:false},{name:'Email', sortEnabled:false},{name:'First Name', sortEnabled:false},{name:'Last Name', sortEnabled:false},{name:'URL', sortEnabled:false},{name:'Accessed Time', sortEnabled:false}]}
          accessor={['ipAddress', 'emailId', 'firstName', 'lastName', 'url', 'createdTime']}
          data={auditListData}
          totalCount={auditData?.audits?.totalCount}
          currentPage={pagination.offset}
          currentLimit={pagination.limit}
          isDateFilter
          selectedDateObj={(date)=>onDateChange(date)}
          dateFilterLabel={ `${moment(CalculateUTCTzToUserTz(selectedDate.startDate)).format('MM-DD-YYYY')} - ${moment(CalculateUTCTzToUserTz(selectedDate.endDate)).format('MM-DD-YYYY')}`}
          filterOptions={[{key:"Audit Logs", value:"audit"},{key:"Login Logs", value:"access"}]}
          isFilterEnabled
          filterAction={(filter)=>{handleFilterChange(filter)}}
          defaultFilter={channel}
          // isSearchEnabled
          searchCallback={(text) => handleSearch(text)}
          searchKey={searchText}
          isSortEnabled
          handleSort={handleSort}
        /> }
        </Grid>
        </Grid>
       
    </div>
}


Audits.propTypes = {
    auditData: PropTypes.objectOf(PropTypes.object).isRequired,
    fetchAuditList: PropTypes.func.isRequired,
    setBreadcrumb: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({ auditData: state.audit })

const mapDispatchToProps = (dispatch) => {
    return {
        fetchAuditList: (data,search,limit,page) => dispatch(auditActions.fetchAuditList(data,search,limit,page)),
        setBreadcrumb: (menu) => dispatch(menuActions.changeMenu(menu))
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(Audits);


