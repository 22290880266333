import * as actionTypes from '../actions/actionTypes';

const initialState = {  
  dashboardDetails:{},  
  isLoading: false,
  isError: false,
  filters:{
    isLoading:false,
    isError:false,
    data:{}
  }
}

const dashboardReducer = (state = initialState, action = {}) => {

  const newState = { ...state };

  switch (action.type) {
    case actionTypes.FETCH_DASHBOARD_DETAILS:
      return { ...newState, isLoading: true };

    case actionTypes.FETCH_DASHBOARD_DETAILS_SUCCESS:
      return { ...newState, isLoading: false, dashboardDetails: { ...action.payload } };

    case actionTypes.FETCH_DASHBOARD_DETAILS_FAILURE:
      return { ...newState, isLoading: false, dashboardDetails: { ...newState.dashboardDetails }, isError: true };

      case actionTypes.FETCH_DASH_FILTER_DATA:
      return { ...newState, filters: { isLoading: true, isError:false  } };

    case actionTypes.FETCH_DASH_FILTER_DATA_SUCCESS:
      return { ...newState,  filters: { isLoading: false, isError:false, data:{...action.payload}  } };

    case actionTypes.FETCH_DASH_FILTER_DATA_FAILURE:
      return { ...newState,  filters: { isLoading: false, isError:true  } };
    
    default:
      return { ...newState };
  }
};


export default dashboardReducer;