export const SIGN_IN = 'SIGN_IN';
export const SIGN_IN_SUCCESS = 'SIGN_IN_SUCCESS';
export const SIGN_IN_FAILURE = 'SIGN_IN_FAILURE';

export const SIGN_UP = 'SIGN_UP';
export const SIGN_UP_SUCCESS = 'SIGN_UP_SUCCESS';
export const SIGN_UP_FAILURE = 'SIGN_UP_FAILURE';

export const VERIFY_CODE = 'VERIFY_CODE';
export const VERIFY_CODE_SUCCESS = 'VERIFY_CODE_SUCCESS';
export const VERIFY_CODE_FAILURE = 'VERIFY_CODE_FAILURE';

export const FORGOT_PWD = 'FORGOT_PWD';
export const FORGOT_PWD_SUCCESS = 'FORGOT_PWD_SUCCESS';
export const FORGOT_PWD_FAILURE = 'FORGOT_PWD_FAILURE';

export const SIGN_OUT = 'SIGN_OUT';
export const SIGN_OUT_SUCCESS = 'SIGN_OUT_SUCCESS';
export const SIGN_OUT_FAILURE = 'SIGN_OUT_FAILURE';

export const FETCH_CAMPAIGN_LIST = 'FETCH_CAMPAIGN';
export const FETCH_CAMPAIGN_LIST_SUCCESS = 'FETCH_CAMPAIGN_LIST_SUCCESS';
export const FETCH_CAMPAIGN_LIST_FAILURE = 'FETCH_CAMPAIGN_LIST_FAILURE';

export const CREATE_TEMPLATE= 'CREATE_TEMPLATE';
export const CREATE_TEMPLATE_SUCCESS = 'CREATE_TEMPLATE_SUCCESS';
export const CREATE_TEMPLATE_FAILURE = 'CREATE_TEMPLATE_FAILURE';


export const SET_EDIT_TEMPLATE_DATA= 'SET_EDIT_TEMPLATE_DATA';
export const EDIT_TEMPLATE= 'EDIT_TEMPLATE';
export const EDIT_TEMPLATE_SUCCESS = 'EDIT_TEMPLATE_SUCCESS';
export const EDIT_TEMPLATE_FAILURE = 'EDIT_TEMPLATE_FAILURE';

export const DELETE_TEMPLATE= 'DELETE_TEMPLATE';
export const DELETE_TEMPLATE_SUCCESS = 'DELETE_TEMPLATE_SUCCESS';
export const DELETE_TEMPLATE_FAILURE = 'DELETE_TEMPLATE_FAILURE';

export const FETCH_TEMPLATE_LIST = 'FETCH_TEMPLATE';
export const FETCH_TEMPLATE_LIST_SUCCESS = 'FETCH_TEMPLATE_LIST_SUCCESS';
export const FETCH_TEMPLATE_LIST_FAILURE = 'FETCH_TEMPLATE_LIST_FAILURE';

export const FETCH_CAMPAIGN_DETAILS = 'FETCH_CAMPAIGN_DETAILS';
export const FETCH_CAMPAIGN_DETAILS_SUCCESS = 'FETCH_CAMPAIGN_DETAILS_SUCCESS';
export const FETCH_CAMPAIGN_DETAILS_FAILURE = 'FETCH_CAMPAIGN_DETAILS_FAILURE';


export const FETCH_CAMPAIGN_SOURCEFILE = 'FETCH_CAMPAIGN_SOURCEFILE';
export const FETCH_CAMPAIGN_SOURCEFILE_SUCCESS = 'FETCH_CAMPAIGN_SOURCEFILE_SUCCESS';
export const FETCH_CAMPAIGN_SOURCEFILE_FAILURE = 'FETCH_CAMPAIGN_SOURCEFILE_FAILURE';

export const CREATE_CAMPAIGN = 'CREATE_CAMPAIGN';
export const CREATE_CAMPAIGN_SUCCESS = 'CREATE_CAMPAIGN_SUCCESS';
export const CREATE_CAMPAIGN_FAILURE = 'CREATE_CAMPAIGN_FAILURE';

export const UPLOAD_FILE = 'UPLOAD_FILE';
export const UPLOAD_FILE_SUCCESS = 'UPLOAD_FILE_SUCCESS';
export const UPLOAD_FILE_FAILURE = 'UPLOAD_FILE_FAILURE';

export const GET_FILTERED_MESSAGES = 'GET_FILTERED_MESSAGES';
export const GET_FILTERED_MESSAGES_SUCCESS = 'GET_FILTERED_MESSAGES_SUCCESS';
export const GET_FILTERED_MESSAGES_FAILURE = 'GET_FILTERED_MESSAGES_FAILURE';

export const UPDATE_CAMPAIGN = 'UPDATE_CAMPAIGN';
export const UPDATE_CAMPAIGN_SUCCESS = 'UPDATE_CAMPAIGN_SUCCESS';
export const UPDATE_CAMPAIGN_FAILURE = 'UPDATE_CAMPAIGN_FAILURE';

export const CANCEL_CAMPAIGN = 'CANCEL_CAMPAIGN';
export const CANCEL_CAMPAIGN_SUCCESS = 'CANCEL_CAMPAIGN_SUCCESS';
export const CANCEL_CAMPAIGN_FAILURE = 'CANCEL_CAMPAIGN_FAILURE';

export const GET_CREATE_CAMPAIGN_VALUES = 'GET_CREATE_CAMPAIGN_VALUES';
export const SET_CREATE_CAMPAIGN_VALUES = 'SET_CREATE_CAMPAIGN_VALUES';
export const CLEAR_CREATE_CAMPAIGN_VALUES = 'CLEAR_CREATE_CAMPAIGN_VALUES';

export const FETCH_DASHBOARD_DETAILS = 'FETCH_DASHBOARD_DETAILS';
export const FETCH_DASHBOARD_DETAILS_SUCCESS = 'FETCH_DASHBOARD_DETAILS_SUCCESS';
export const FETCH_DASHBOARD_DETAILS_FAILURE = 'FETCH_DASHBOARD_DETAILS_FAILURE';

export const FETCH_USERS_LIST = 'FETCH_USERS_LIST';
export const FETCH_USERS_LIST_SUCCESS = 'FETCH_USERS_LIST_SUCCESS';
export const FETCH_USERS_LIST_FAILURE = 'FETCH_USERS_LIST_FAILURE';

export const FETCH_REPORTS_LIST = 'FETCH_REPORTS_LIST';
export const FETCH_REPORTS_LIST_SUCCESS = 'FETCH_REPORTS_LIST_SUCCESS';
export const FETCH_REPORTS_LIST_FAILURE = 'FETCH_REPORTS_LIST_FAILURE';

export const GET_FAILED_MESSAGE_REPORT = 'GET_FAILED_MESSAGE_REPORT';
export const GET_FAILED_MESSAGE_REPORT_SUCCESS = 'GET_FAILED_MESSAGE_REPORT_SUCCESS';
export const GET_FAILED_MESSAGE_REPORT_FAILURE = 'GET_FAILED_MESSAGE_REPORT_FAILURE';

export const DOWNLOAD_FAILED_MESSAGE_REPORT = 'DOWNLOAD_FAILED_MESSAGE_REPORT';
export const DOWNLOAD_FAILED_MESSAGE_REPORT_SUCCESS = 'DOWNLOAD_FAILED_MESSAGE_REPORT_SUCCESS';
export const DOWNLOAD_FAILED_MESSAGE_REPORT_FAILURE = 'DOWNLOAD_FAILED_MESSAGE_REPORT_FAILURE';

export const FETCH_EMAIL_CONFIGURATION = 'FETCH_EMAIL_CONFIGURATION';
export const FETCH_EMAIL_CONFIGURATION_SUCCESS = 'FETCH_EMAIL_CONFIGURATION_SUCCESS';
export const FETCH_EMAIL_CONFIGURATION_FAILURE = 'FETCH_EMAIL_CONFIGURATION_FAILURE';


export const FETCH_TEMPLATE_EMAIL_CONFIGURATION = 'FETCH_TEMPLATE_EMAIL_CONFIGURATION';
export const FETCH_TEMPLATE_EMAIL_CONFIGURATION_SUCCESS = 'FETCH_TEMPLATE_EMAIL_CONFIGURATION_SUCCESS';
export const FETCH_TEMPLATE_EMAIL_CONFIGURATION_FAILURE = 'FETCH_TEMPLATE_EMAIL_CONFIGURATION_FAILURE';
export const RESET_TEMPLATE_EMAIL_CONFIGURATION = 'RESET_TEMPLATE_EMAIL_CONFIGURATION';


export const CREATE_EMAIL_CONFIGURATION = 'CREATE_EMAIL_CONFIGURATION';
export const CREATE_EMAIL_CONFIGURATION_SUCCESS = 'CREATE_EMAIL_CONFIGURATION_SUCCESS';
export const CREATE_EMAIL_CONFIGURATION_FAILURE = 'CREATE_EMAIL_CONFIGURATION_FAILURE';

export const UPDATE_EMAIL_CONFIGURATION = 'UPDATE_EMAIL_CONFIGURATION';
export const UPDATE_EMAIL_CONFIGURATION_SUCCESS = 'UPDATE_EMAIL_CONFIGURATION_SUCCESS';
export const UPDATE_EMAIL_CONFIGURATION_FAILURE = 'UPDATE_EMAIL_CONFIGURATION_FAILURE';

export const FETCH_ANALYTICS_DATA = 'FETCH_ANALYTICS_DATA';
export const FETCH_ANALYTICS_DATA_SUCCESS = 'FETCH_ANALYTICS_DATA_SUCCESS';
export const FETCH_ANALYTICS_DATA_FAILURE = 'FETCH_ANALYTICS_DATA_FAILURE';

export const EXPORT_ANALYTICS_DATA = 'EXPORT_ANALYTICS_DATA';
export const EXPORT_ANALYTICS_DATA_SUCCESS = 'EXPORT_ANALYTICS_DATA_SUCCESS';
export const EXPORT_ANALYTICS_DATA_FAILURE = 'EXPORT_ANALYTICS_DATA_FAILURE';

export const FETCH_FILTER_DATA = 'FETCH_FILTER_DATA';
export const FETCH_FILTER_DATA_SUCCESS = 'FETCH_FILTER_DATA_SUCCESS';
export const FETCH_FILTER_DATA_FAILURE = 'FETCH_FILTER_DATA_FAILURE';

export const TRIGGER_OPT_OUT = 'TRIGGER_OPT_OUT';
export const TRIGGER_OPT_OUT_SUCCESS = 'TRIGGER_OPT_OUT_SUCCESS';
export const TRIGGER_OPT_OUT_FAILURE = 'TRIGGER_OPT_OUT_FAILURE';

export const FETCH_OPT_OUT_LIST = 'FETCH_OPT_OUT_LIST';
export const FETCH_OPT_OUT_LIST_SUCCESS = 'FETCH_OPT_OUT_LIST_SUCCESS';
export const FETCH_OPT_OUT_LIST_FAILURE = 'FETCH_OPT_OUT_LIST_FAILURE';

export const EXPORT_OPT_OUT_LIST = 'EXPORT_OPT_OUT_LIST';
export const EXPORT_OPT_OUT_LIST_SUCCESS = 'EXPORT_OPT_OUT_LIST_SUCCESS';
export const EXPORT_OPT_OUT_LIST_FAILURE = 'EXPORT_OPT_OUT_LIST_FAILURE';


export const FETCH_AUDITS = 'FETCH_AUDITS';
export const FETCH_AUDITS_SUCCESS = 'FETCH_AUDITS_SUCCESS';
export const FETCH_AUDITS_FAILURE = 'FETCH_AUDITS_FAILURE';


export const AZURE_SIGN_IN = 'AZURE_SIGN_IN';
export const AZURE_SIGN_IN_SUCCESS = 'AZURE_SIGN_IN_SUCCESS';
export const AZURE_SIGN_IN_FAILURE = 'AZURE_SIGN_IN_FAILURE';
export const AZURE_LOGOUT = 'AZURE_LOGOUT';

export const PRE_SIGN_IN = 'PRE_SIGN_IN';
export const PRE_SIGN_IN_SUCCESS = 'PRE_SIGN_IN_SUCCESS';
export const PRE_SIGN_IN_FAILURE = 'PRE_SIGN_IN_FAILURE';

export const FETCH_RECONCILE_LIST = 'FETCH_RECONCILE_LIST';
export const FETCH_RECONCILE_LIST_SUCCESS = 'FETCH_RECONCILE_LIST_SUCCESS';
export const FETCH_RECONCILE_LIST_FAILURE = 'FETCH_RECONCILE_LIST_FAILURE';

export const FETCH_RECONCILE_STATUS = 'FETCH_RECONCILE_STATUS';
export const FETCH_RECONCILE_STATUS_SUCCESS = 'FETCH_RECONCILE_STATUS_SUCCESS';
export const FETCH_RECONCILE_STATUS_FAILURE = 'FETCH_RECONCILE_STATUS_FAILURE';

export const REGENERATE_STATUS = 'REGENERATE_STATUS';
export const REGENERATE_STATUS_SUCCESS = 'REGENERATE_STATUS_SUCCESS';
export const REGENERATE_STATUS_FAILURE = 'REGENERATE_STATUS_FAILURE';


export const DELETE_USER = 'DELETE_USER';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_FAILURE = 'DELETE_USER_FAILURE';


export const EDIT_USER = 'EDIT_USER';
export const EDIT_USER_SUCCESS = 'EDIT_USER_SUCCESS';
export const EDIT_USER_FAILURE = 'EDIT_USER_FAILURE';
export const SET_EDIT_USER_DATA= 'SET_EDIT_USER_DATA';


export const FETCH_INVALID_REPORT_DATA = 'FETCH_INVALID_REPORT_DATA';
export const FETCH_INVALID_REPORT_DATA_SUCCESS = 'FETCH_INVALID_REPORT_DATA_SUCCESS';
export const FETCH_INVALID_REPORT_DATA_FAILURE = 'FETCH_INVALID_REPORT_DATA_FAILURE';

export const FETCH_INVALID_FILTER_DATA = 'FETCH_INVALID_FILTER_DATA';
export const FETCH_INVALID_FILTER_DATA_SUCCESS = 'FETCH_INVALID_FILTER_DATA_SUCCESS';
export const FETCH_INVALID_FILTER_DATA_FAILURE = 'FETCH_INVALID_FILTER_DATA_FAILURE';

export const EXPORT_INVALID_DATA = 'EXPORT_INVALID_DATA';
export const EXPORT_INVALID_DATA_SUCCESS = 'EXPORT_INVALID_DATA_SUCCESS';
export const EXPORT_INVALID_DATA_FAILURE = 'EXPORT_INVALID_DATA_FAILURE';


export const MENU_CHANGE = 'MENU_CHANGE';
export const SUB_MENU_CHANGE = 'SUB_MENU_CHANGE';


export const CREATE_INSTANT_CAMPAIGN = 'CREATE_INSTANT_CAMPAIGN';
export const CREATE_INSTANT_CAMPAIGN_SUCCESS = 'CREATE_INSTANT_CAMPAIGN_SUCCESS';
export const CREATE_INSTANT_CAMPAIGN_FAILURE = 'CREATE_INSTANT_CAMPAIGN_FAILURE';


export const FETCH_INSTANT_CAMPAIGNS_LIST = 'FETCH_INSTANT_CAMPAIGNS_LIST';
export const FETCH_INSTANT_CAMPAIGNS_LIST_SUCCESS = 'FETCH_INSTANT_CAMPAIGNS_LIST_SUCCESS';
export const FETCH_INSTANT_CAMPAIGNS_LIST_FAILURE = 'FETCH_INSTANT_CAMPAIGNS_LIST_FAILURE';


export const FETCH_CAMPAIGNS_LOV = 'FETCH_CAMPAIGNS_LOV';
export const FETCH_CAMPAIGNS_LOV_SUCCESS = 'FETCH_CAMPAIGNS_LOV_SUCCESS';
export const FETCH_CAMPAIGNS_LOV_FAILURE = 'FETCH_CAMPAIGNS_LOV_FAILURE';


export const FETCH_CAMPAIGN_FILES_LIST = 'FETCH_CAMPAIGN_FILES_LIST';
export const FETCH_CAMPAIGN_FILES_SUCCESS = 'FETCH_CAMPAIGN_FILES_SUCCESS';
export const FETCH_CAMPAIGN_FILES_FAILURE = 'FETCH_CAMPAIGN_FILES_FAILURE';


export const FETCH_FILE_DETAILS = 'FETCH_FILE_DETAILS';
export const FETCH_FILE_DETAILS_SUCCESS = 'FETCH_FILE_DETAILS_SUCCESS';
export const FETCH_FILE_DETAILS_FAILURE = 'FETCH_FILE_DETAILS_FAILURE';

export const FETCH_ADHOC_LOGS = 'FETCH_ADHOC_LOGS';
export const FETCH_ADHOC_LOGS_SUCCESS = 'FETCH_ADHOC_LOGS_SUCCESS';
export const FETCH_ADHOC_LOGS_FAILURE = 'FETCH_ADHOC_LOGS_FAILURE';

export const FETCH_EXTERNAL_LOGS = 'FETCH_EXTERNAL_LOGS';
export const FETCH_EXTERNAL_LOGS_SUCCESS = 'FETCH_EXTERNAL_LOGS_SUCCESS';
export const FETCH_EXTERNAL_LOGS_FAILURE = 'FETCH_EXTERNAL_LOGS_FAILURE';

export const FETCH_DASH_FILTER_DATA = 'FETCH_DASH_FILTER_DATA';
export const FETCH_DASH_FILTER_DATA_SUCCESS = 'FETCH_DASH_FILTER_DATA_SUCCESS';
export const FETCH_DASH_FILTER_DATA_FAILURE = 'FETCH_DASH_FILTER_DATA_FAILURE';

export const CREATE_BILLING='CREATE_BILLING';
export const CREATE_BILLING_SUCCESS='CREATE_BILLING_SUCCESS';
export const CREATE_BILLING_FAILURE='CREATE_BILLING_FAILURE';
export const RESET_CREATE_BILLING_STATE='RESET_CREATE_BILLING_STATE';

export const FETCH_BILLING_DATA='FETCH_BILLING_DATA';
export const FETCH_BILLING_DATA_SUCCESS='FETCH_BILLING_DATA_SUCCESS';
export const FETCH_BILLING_DATA_FAILURE='FETCH_BILLING_DATA_FAILURE';

export const FETCH_BILLING_LOV='FETCH_BILLING_LOV';
export const FETCH_BILLING_LOV_SUCCESS='FETCH_BILLING_LOV_SUCCESS';
export const FETCH_BILLING_LOV_FAILURE='FETCH_BILLING_LOV_FAILURE';

export const FETCH_BILLING_DASHBOARD_FILTERS='FETCH_BILLING_DASHBOARD_FILTERS';
export const FETCH_BILLING_DASHBOARD_FILTERS_SUCCESS='FETCH_BILLING_DASHBOARD_FILTERS_SUCCESS';
export const FETCH_BILLING_DASHBOARD_FILTERS_FAILURE='FETCH_BILLING_DASHBOARD_FILTERS_FAILURE';