/* eslint-disable */
import React from 'react';
import { styled } from "@mui/material/styles";
import { Link } from 'react-router-dom';

import { Drawer, List, ListItem, ListItemText } from '@mui/material';

import SideMenuItems from './SideMenuItems';
import '../styles/SideMenu.scss';
import brandLogo from '../assets/mass-logo.svg';
import appConfig from '../config/appConfig';

const drawerWidth = "13.5rem";


const DrawerHeader = styled("div")(({ theme }) => ({
  ...theme.mixins.toolbar,
  textAlign: "center",
  paddingTop: "18px"
}));





const SideMenu = () => {

  const activeMenu = window.location.pathname.slice(1).split('/')[0];
  const userDetails = JSON.parse(window.sessionStorage.getItem("userInfo"));

  return (
    <Drawer
      sx={{
        width: drawerWidth,
        "& .MuiDrawer-paper": {
          width: drawerWidth,
          boxShadow: "0px 3px 6px #00000029"
        }
      }}
      variant="persistent"
      anchor="left"
      open
    >
      <DrawerHeader>
        <div><img height={80} src={brandLogo} alt="logo" /></div>
      </DrawerHeader>
      <List>
        {SideMenuItems.filter((menu)=>menu.isShow).map((menu) => {
          return (userDetails?.role === "FPRADN" ? menu.isPRAdminRoute : userDetails?.role === "FBSUSR" ? menu.isBusRoute : menu.isAdminRoute) && 
            <ListItem key={menu.route}
            className="sideMenuItem"
              sx={{
                height: "35px",
                border: '1px solid #ddd',
                "&.Mui-selected": {
                  color: "#000",
                  backgroundColor: "#EDF6F4",
                  height: "35px",
                }
              }}

              selected={activeMenu === menu.route || menu.subRoutes.includes(activeMenu)}>
                <Link key={menu.route} to={`/${menu.route}`} className='sideMenuLink'>
              <ListItemText key={menu.route} primary={menu.name} sx={{ '& .MuiTypography-root': { fontFamily: appConfig.fontfamily } }} />
              </Link>
            </ListItem>
  
          
        })}
      </List>
    </Drawer>

  )

}


export default SideMenu;