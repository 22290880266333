import React from "react";
import {Routes,Route,Navigate } from "react-router-dom";
import MainLayout from '../Layout/MainLayout';
import TenantLayout from '../Layout/TenantLayout';
import SignIn from "../pages/SignIn";
import Campaign from "../pages/Campaign";
import CampaignSnapshot from "../pages/CampaignSnapshot";
// import OptOut from "../pages/Optout";
import CreateCampaign from "../pages/CreateCampaign";
import Users from "../pages/Users";
import Template from "../pages/Template";
import CreateTemplate from "../pages/CreateTemplate";
import EditTemplate from "../pages/EditTemplate";
import EmailConfiguration from "../pages/EmailConfiguration";
import Reports from "../pages/Reports";
import OptoutList from "../pages/OptoutList";
import Audits from "../pages/Audits";
import Reconcile from "../pages/Reconcile";
import ErrorLogs from "../pages/ErrorLogs";
import Dashboard from "../pages/Dashboard";
// import DashboardV3 from "../pages/Dashboardv3";
import CreateUser from "../pages/CreateUser";
import EditUser from "../pages/EditUser";
import Broadcast from "../pages/instant-notifications/Broadcast";
import InstantMessages from "../pages/instant-notifications/InstantMessages";
import UploadFile from "../pages/UploadFile";
import CampaignFiles from "../pages/CampaignFiles";
import Gloassary from "../pages/Gloassary";
import TenantSelection from '../pages/TenantSelection';
import AdhocLogs from "../pages/AdhocLogs";
import ExternalLogs from "../pages/ExternalLogs";
import { hasAccess } from "../components/Utils";
import Billing from "../pages/Billing";

const NotFound =  () => {
    return (
            <div style={{textAlign:"center"}}>
                <h2>Oh ho! 404, Page Not Found</h2>
                <span>Click to return <a href="/">Home Page</a></span>
            </div>
    );
};

const wrapWithLayout = (comp) =>{
  return <MainLayout>{comp}</MainLayout>
}
const wrapWithTenantLayout = (comp) =>{
  return <TenantLayout>{comp}</TenantLayout>
}

const AppRoutes =  () => {
    const auth = JSON.parse(window.sessionStorage.getItem('userInfo'))
    const tenant = JSON.parse(window.sessionStorage.getItem('tenantInfo'))

    const hasCampaignAccess = hasAccess( 'CAM');
    const hasTemplateAccess = hasAccess('TEM');
    const hasOptOutAccess = hasAccess('OPT');
    const hasUserAccess = hasAccess('USR');
    const hasLogAccess = hasAccess('LOG');
    const hasReportAccess = hasAccess('RPT');
    const hasReconcileAccess = hasAccess('REC');
    const hasInvalidReportAccess = hasAccess('INV');
    const hasInstantCampaignAccess = hasAccess('INC');
    const hasSettingsAccess = hasAccess('SET');
    const hasInboundAccess = hasAccess('ADH');
    const hasOutboundAccess = hasAccess('EXT');
    const hasBillingAccess = hasAccess('BIL');
    // To fine grain access control please add your authority code and manage access here
  
    if(!auth || !tenant) {
        return (
        <Routes>
        <Route path="/login" element={<SignIn />} />
        <Route path="/" element={<SignIn />} />
        {/* <Route exact path="/optout" element={<OptOut/>} /> */}
        <Route path="*" element={<Navigate to="/login" />} />
        <Route path="/tenantselection" element={wrapWithTenantLayout(<TenantSelection />)}/>
     </Routes>
     )
    }
    
    return (
      <Routes>
      {hasCampaignAccess && <Route path="/campaign" element={wrapWithLayout(<Campaign/>)} />}
      {/* <Route exact path="/dashboard" element={wrapWithLayout(<DashboardV3/>)} /> */}
      <Route exact path="/dashboard" element={wrapWithLayout(<Dashboard/>)} />
      {hasTemplateAccess && <Route path="/templates" element={wrapWithLayout(<Template/>)} />}
      <Route exact path="/" element={wrapWithLayout(<Dashboard/>)} />
      {hasCampaignAccess && <Route exact path="/campaigndetails/:id" element={wrapWithLayout(<CampaignSnapshot/>)} />}
      <Route exact path="usermanagement" element={wrapWithLayout(<Users/>)} />
      {hasCampaignAccess && <Route exact path="/createcampaign" element={wrapWithLayout(<CreateCampaign/>)}/>}
      {hasTemplateAccess && <Route exact path="/createtemplate" element={wrapWithLayout(<CreateTemplate/>)}/>}
      {hasTemplateAccess && <Route exact path="/edittemplate" element={wrapWithLayout(<EditTemplate/>)}/>}
      {hasUserAccess && <Route exact path="/createuser" element={wrapWithLayout(<CreateUser/>)}/>}
      {hasUserAccess && <Route exact path="/edituser" element={wrapWithLayout(<EditUser/>)}/>}
      {hasInstantCampaignAccess && <Route exact path="/staffalerts" element={wrapWithLayout(<Broadcast/>)}/>}
      {hasInstantCampaignAccess && <Route exact path="/instant" element={wrapWithLayout(<InstantMessages/>)}/>}
      {hasSettingsAccess && <Route path="/emailconfiguration" element={wrapWithLayout(<EmailConfiguration />)}/>}
      {hasReportAccess && <Route path="/reports" element={wrapWithLayout(<Reports />)}/>}
      {hasInvalidReportAccess && <Route path="/errorlog" element={wrapWithLayout(<ErrorLogs />)}/>}
      {hasOptOutAccess && <Route path="/optoutlist" element={wrapWithLayout(<OptoutList />)}/>}
      {hasLogAccess && <Route path="/audits" element={wrapWithLayout(<Audits />)}/>}
      {hasReconcileAccess && <Route path="/reconcile" element={wrapWithLayout(<Reconcile />)}/>}
      {hasCampaignAccess && <Route path="/uploadfile" element={wrapWithLayout(<UploadFile />)}/>}
      {hasCampaignAccess && <Route path="/campaignfiles" element={wrapWithLayout(<CampaignFiles />)}/>}
      {hasInboundAccess && <Route path="/externallogs" element={wrapWithLayout(<ExternalLogs />)}/>}
      {hasOutboundAccess && <Route path="/adhoclogs" element={wrapWithLayout(<AdhocLogs />)}/>}
      {hasBillingAccess && <Route path="/billing" element={wrapWithLayout(<Billing />)}/>}

      <Route path="/glossary" element={wrapWithLayout(<Gloassary />)}/>
      <Route path="/tenantselection" element={wrapWithTenantLayout(<TenantSelection />)}/>

      <Route path="/signout" element={<Navigate to="/login" />} />
      <Route path="/login" element={<SignIn />} />
      <Route path="*" element={<NotFound/>} />
    </Routes>
    )
};


export default AppRoutes;















