import React, { useEffect, useState, useRef } from 'react';
import { Grid, TextField, Autocomplete, IconButton } from '@mui/material';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { endOfDay, startOfDay } from 'date-fns'
import moment from 'moment';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined';

import TableCell from '@mui/material/TableCell';

import FormControl from '@mui/material/FormControl';


// import * as Styled from '../components/styled-components/Campaign';
import DefaultTable from '../components/Table';
import SkeletonGridLoader from '../components/SkeletonGridLoader';

import * as adhocLogs from '../store/actions/apiLogs';

import DateRangePickerComponent from '../components/DateRangePicker/DateRangePickerComponent';


import { CalculateUTCTzToUserTz, CalculateUserTzToUTCTz, splitDate, splitTime, formatDTADate } from '../components/Utils';
import appConfig from '../config/appConfig';
import { statusList } from '../config/config';

const dropDownlistCss = { mt: 1, mb: 1, background: "#fcfcfb", border: `1px solid ${appConfig.colors.darkblue}`, borderRadius: "5px", width: "100%", '.MuiAutocomplete-input': { height: "5px" } };
const calenderCss = { mt: 1, mb: 1, background: "#fcfcfb", width: "100%", '.MuiAutocomplete-input': { height: "5px" } };

const gridItemCss = { '&.MuiGrid-item': { pt: 0, pl: 2, pr: 2 } };

const mb = { marginBottom: 0, fontWeight:"bold",color: appConfig.colors.darkgray};


const AdhocLogs = ({ apiLogsData, fetchAdhocAPILogsData }) => {
    const {  handleSubmit } = useForm();
    const [selectedDate, setSelectedDate] = useState({
        startDate: startOfDay(new Date()),
        endDate: endOfDay(new Date())
    });

    const filterInitialState = {
        channels: { key: "SMS", value: "SMS" },
        status: {key :"all",value:"All"},
        startDate: CalculateUserTzToUTCTz(selectedDate?.startDate?.toISOString()),
        endDate: CalculateUserTzToUTCTz(selectedDate?.endDate?.toISOString())
    }
    const [filterData, setFilterData] = useState(filterInitialState);

    const [pagination, setPagination] = useState({ limit: 10, offset: 1 });
    const [searchText, setSearchText] = useState("");
    const isMounted = useRef(false);
    const [id, setId] = useState(null);
    const [selected, setSelected] = useState(null);
    const [enableRowDetails, setEnableRowDetails] = useState(false);
    const [selectedRow,setSelectedRow] = useState(null);
    const [sortValue, setSortValue] = useState({sortBy:"createdTime", sortOrder: "desc"});

    const ChannelList = [
        { key: "SMS", value: "SMS" },
        { key: "Email", value: "Email" },
    ];


    const handleSearch = (search) => {
        isMounted.current = false;
        setSearchText(search);
    }

    const handleFilter = (data, input) => {
        console.log("++DATA",data, input)
        switch (input) {
            case "status":
                setFilterData({
                    ...filterData,
                    status: { key: data.label, value: data.value },
                })
                break;
            case "channel":
                setFilterData({
                    ...filterData,
                    channels: { key: data.label, value: data.id },
                    // status:{key :"all",value:"All"}
                })
                setPagination({ limit:10, offset:1 })
                break;

            case "date":
                setFilterData({
                    ...filterData,
                    endDate: CalculateUserTzToUTCTz(data?.[0]?.endDate ? endOfDay(data?.[0]?.endDate) : endOfDay(data?.endDate)),
                    startDate: CalculateUserTzToUTCTz(data?.[0]?.startDate || startOfDay(data?.startDate)),
                    // status:{key :"all",value:"All"}
                })
                setSelectedDate({
                            startDate: CalculateUserTzToUTCTz(data?.[0]?.startDate || startOfDay(data?.startDate)),
                            endDate: CalculateUserTzToUTCTz(data?.[0]?.endDate ? endOfDay(data?.[0]?.endDate) : endOfDay(data?.endDate))
                        })
                break;

            default:
                setFilterData({
                    ...filterData,
                })
        }
    }

    const filterValues = {
        communicationChannel: filterData.channels.value.toLowerCase(),
        isRequestSuccess: filterData.status.value.toLowerCase() ,
        startDate: filterData.startDate.toISOString(),
        endDate: filterData.endDate.toISOString(),
        sortBy:sortValue.sortBy,
        sortOrder:sortValue.sortOrder
    }

    
    useEffect(() => {
        setFilterData(filterInitialState);
    }, [])


    useEffect(() => {
        isMounted.current = true;
        fetchAdhocAPILogsData(pagination.limit, pagination.offset, filterValues, searchText);
    }, [filterData.channels,filterData.startDate,filterData.endDate,filterData.status])

    useEffect(()=>{
        fetchAdhocAPILogsData(pagination.limit, pagination.offset, filterValues, searchText);
    },[JSON.stringify(pagination),JSON.stringify(sortValue)])

    useEffect(()=>{
        if (!isMounted.current) {
        setPagination({limit:pagination.limit,offset:1})
        fetchAdhocAPILogsData(pagination.limit, pagination.offset, filterValues, searchText);
        }
    },[searchText])


    const onSubmit = () => {
        console.log(filterValues)
        fetchAdhocAPILogsData(pagination.limit, pagination.offset, filterValues);
    }; 
    
    const onRefresh = () => {
        setPagination({ offset: 1, limit: 10 })
        setSearchText("");
        fetchAdhocAPILogsData(pagination.limit, pagination.offset, filterValues, searchText)
      }

    const renderCells = (row) => {
        return <>
            <TableCell sx={{fontFamily:appConfig.fontfamily,fontSize:appConfig.font16}}>{row.id ? row.id : '-'}</TableCell>
            <TableCell sx={{fontFamily:appConfig.fontfamily,fontSize:appConfig.font16}}>{row.communicationValue? row.communicationValue : '-'}</TableCell>
            <TableCell sx={{fontFamily:appConfig.fontfamily,fontSize:appConfig.font16}}>{row.triggeredBy? row.triggeredBy : '-'}</TableCell>
            <TableCell sx={{fontFamily:appConfig.fontfamily,fontSize:appConfig.font16}}>{formatDTADate(splitDate(CalculateUTCTzToUserTz(row.createdTime)))} {splitTime(CalculateUTCTzToUserTz(row.createdTime))}</TableCell>
            <TableCell sx={{fontFamily:appConfig.fontfamily,fontSize:appConfig.font16}}>{row.isRequesSuccess && row.isRequesSuccess ? 'Success' : 'Failure'}</TableCell>
        </>
    }

    // const onClear = () => {
    //     const filterInitValues = {
    //                 communicationChannel: filterInitialState.channels.value,
    //                 isRequestSuccess: "all",
    //                 startDate: CalculateUserTzToUTCTz(startOfDay(new Date().setDate(new Date().getDate() - 30))).toISOString(),
    //                 endDate: CalculateUserTzToUTCTz(endOfDay(new Date())).toISOString()
    //             }
    //     setFilterData(filterInitialState);
    //     setSelectedDate({
    //         startDate: CalculateUserTzToUTCTz(startOfDay(new Date().setDate(new Date().getDate() - 30))),
    //         endDate: CalculateUserTzToUTCTz(endOfDay(new Date()))
    //     })
    //     fetchAdhocAPILogsData(pagination.limit, pagination.offset, filterInitValues, searchText);
    // }


    const handlePagination = (limit, offset) => {
        setPagination({ limit, offset })
    }

    const handleSort = (value) => {
        setSortValue(value)
    }

    const renderDetailButton = (row) => {
        return <div>
            <IconButton className="pad-0" onClick={() => {
            setEnableRowDetails(id !== row.id ? true : !enableRowDetails);
            setSelectedRow(row);
            setId(row.id);
            setSelected(row.id);
            }} title='View Details' aria-label="view details" size="small">
            {id === row.id && enableRowDetails ? <KeyboardArrowUpOutlinedIcon sx={{ border: `1px solid ${appConfig.colors.darkblue}`, fontSize: 30, borderRadius: "50%", color: appConfig.colors.darkblue }} /> : <KeyboardArrowDownOutlinedIcon sx={{ border: `1px solid ${appConfig.colors.darkblue}`, fontSize: 30, borderRadius: "50%", color: appConfig.colors.darkblue }} fontSize="inherit" />}
            </IconButton>
        </div>
    }
    
    const renderGrid = (label, value) => {
        return <div className='data-grid'>
            <span className='inline-blk mar-r-10'><b>{label}:</b></span>
            <pre style={{whiteSpace:"pre-wrap"}}>{value}</pre>
        </div>
    }

    const loadRowDetails = () => {
        return  <Grid container spacing={2} padding={1}>
        <Grid item xs={12} sm={12} xl={6} lg={6} md={6}>
        <div style={{width:"35vw"}}>
        {renderGrid('Request', JSON.stringify(selectedRow.request,null, 2)|| '-')}
        </div>
        </Grid>

        <Grid item xs={12} sm={12} xl={6} lg={6} md={6}>
        <div style={{width:"35vw"}}>
        {renderGrid('Response', JSON.stringify(selectedRow.response,null, 2) || '-')}
        </div>
        </Grid>
        </Grid>
    }

    // useEffect(() => {
    //     loadRowDetails();
    //   }, [id]);

    return <div>

        <Grid item xs={6} sm={6} xl={6} lg={6} md={6}>
        <h1 className='page-heading' ><b>Inbound API Logs</b></h1>
        </Grid>
        <hr style={{ border: "3px solid #EDF6F5", margin: "20px 0" }} />

            <div className='filter-container' >
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Grid container spacing={2}>
                        <Grid
                            item
                            xs={12} lg={4} md={4} xl={4}
                            sx={gridItemCss}
                        >
                            <p style={mb}>By Communication Channel</p>
                            <FormControl sx={dropDownlistCss}>
                                <Autocomplete
                                    disablePortal
                                    disableClearable
                                    openOnFocus
                                    clearOnBlur
                                    className='autocomplete-class'
                                    defaultValue={filterData.channels.key ? filterData.channels.key : "Select Channel"}
                                    options={ChannelList.map((item) => ({ label: item.value, id: item.key }))}
                                    renderInput={(params) => {
                                        const newParams = {...params,inputProps:{...params.inputProps,"aria-label":"channel"}}
                                        return <TextField {...newParams} />
                                    }}
                                    onChange={(e, val) => { handleFilter(val, 'channel') }}
                                />
                            </FormControl>
                        </Grid>

                        <Grid item
                            xs={12} lg={4} md={4} xl={4}
                            sx={gridItemCss}>
                            <p style={mb}>By Date</p>
                            <FormControl sx={calenderCss}>
                                <DateRangePickerComponent label={`${moment(CalculateUTCTzToUserTz(filterData.startDate)).format('MM-DD-YYYY')} - ${moment(CalculateUTCTzToUserTz(filterData.endDate)).format('MM-DD-YYYY')}`} cssStyle={{ color: "#111", fontSize: "15px", border: "1px solid #bbb", justifyContent:"flex-start" }} selectedDateObj={(date) => { handleFilter(date, 'date') }} />
                            </FormControl>
                        </Grid>

                        <Grid
                            item
                            xs={12} lg={4} md={4} xl={4}
                            sx={gridItemCss}
                        >
                            <p style={mb}>By Status</p>
                            <FormControl sx={dropDownlistCss}>
                                <Autocomplete
                                    disablePortal
                                    disableClearable
                                    openOnFocus
                                    clearOnBlur
                                    className='autocomplete-class'
                                    defaultValue={filterData.status.value ? filterData.status.value : "Select Status"}
                                    options={statusList.map((item) => ({ label: item.value, value: item.key }))}
                                    renderInput={(params) => {
                                        const newParams = { ...params, inputProps: { ...params.inputProps, "aria-label": "status" } }
                                        return <TextField {...newParams} />
                                    }}
                                    onChange={(e, val) => { handleFilter(val, 'status') }}
                                />
                            </FormControl>
                        </Grid>

                        {/* <Grid
                            item
                            xs={12} lg={1} md={1} xl={1}
                            sx={gridItemCss}
                        >
                            <FormControl sx={dropDownlistCss} className="filter-btn">
                            <Styled.CampaignButton type="submit">
                                    Apply
                                </Styled.CampaignButton>
                            </FormControl>
                        </Grid>
                        <Grid
                            item
                            xs={12} lg={1} md={1} xl={1}
                            sx={gridItemCss}
                        >
                            <FormControl sx={dropDownlistCss} className="filter-btn">
                                <Styled.CancelButton sx={{padding:'5px'}} onClick={() => { onClear() }} >
                                    Clear
                                </Styled.CancelButton>
                            </FormControl>
                        </Grid> */}
                      
                    </Grid>
                </form>
            </div>
        <br />
        <Grid container>
            <Grid item xs={12} sm={12} xl={12} lg={12} md={12}>

                {apiLogsData?.adhoc?.isLoading || apiLogsData?.adhoc?.isLoading ? <SkeletonGridLoader />
                    :
                    <DefaultTable
                        title='Total Records'
                        handlePagination={handlePagination}
                        headers={[{name:'Request ID', sortEnabled:false},{name:'Communication Value', sortEnabled:false},{name:'Triggered By', sortEnabled:false},{name:'Created Time', sortEnabled:true, sortBy:'createdtime'},{name:'Status', sortEnabled:false}]}
                        data={apiLogsData?.adhoc?.data}
                        totalCount={apiLogsData?.adhoc?.count}
                        currentPage={pagination.offset}
                        currentLimit={pagination.limit}
                        isSearchEnabled
                        searchCallback={(text) => handleSearch(text)}
                        searchKey={searchText}
                        searchPlaceholderText='Communication Value'
                        renderCustomCells
                        hasAction={false}
                        renderCells={renderCells}
                        // isExportEnabled
                        // exportCallback={handleExport}
                        hasDetails
                        renderDetailsButton={renderDetailButton}
                        selected={selected}
                        enableRowDetails={enableRowDetails}
                        loadRowDetails={enableRowDetails && loadRowDetails()}
                        isRefreshEnabled
                        refreshCallback={()=>{onRefresh()}}
                        isSortEnabled
                        handleSort={handleSort}
                        sortValue={sortValue}
                    />
                }
            </Grid>
        </Grid>
    </div >
}



const mapStateToProps = (state) => ({ apiLogsData: state.apiLogs })

const mapDispatchToProps = (dispatch) => {
    return {
        fetchAdhocAPILogsData: (limit, page, data, searchText) => dispatch(adhocLogs.getAdhocLogsData(limit, page, data, searchText)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AdhocLogs);

AdhocLogs.propTypes = {
    fetchAdhocAPILogsData: PropTypes.func.isRequired,
    apiLogsData: PropTypes.objectOf(PropTypes.object).isRequired,
    key: PropTypes.objectOf(PropTypes.object).isRequired,

}