import { saveAs } from 'file-saver';
import * as reportsConstants from './actionTypes';
import {config} from '../../config/config';
import request from '../../components/Helper/Request';

const userDetails = JSON.parse(window.sessionStorage.getItem("userInfo"));


export default function getReportsList(limit,page,filterBy) {
    return (dispatch) => {
      dispatch({
        type: reportsConstants.FETCH_REPORTS_LIST
      });
        request({
            method: 'get',
            url:`${config.API_URL}/api/reports/file/${userDetails?.accountId}/${userDetails?.userId}`,
            params:{
              page,
              limit,
              filterBy
            }   
        })  
        .then((response) => {
          dispatch({
            type: reportsConstants.FETCH_REPORTS_LIST_SUCCESS,
            payload: response.data
          });
        })
        .catch((error) => {
          dispatch({
            type: reportsConstants.FETCH_REPORTS_LIST_FAILURE,
            payload:error
          });
        })
    }
}

export function downloadReport(fileName,reportFileName) {
    return (dispatch) => {
        dispatch({
          type: reportsConstants.DOWNLOAD_FAILED_MESSAGE_REPORT
        });
        request({
            method: 'get',
            url:`${config.API_URL}/api/reports/file/download/${userDetails?.accountId}/${userDetails?.userId}`,   
            params: {
              fileName,
              reportFileName
            }
        }).then((response) => {
                const blob = new Blob([response], {type: 'text/dat'});
                saveAs(blob, reportFileName);
          })
          .catch((error) => {
            dispatch({
              type: reportsConstants.DOWNLOAD_FAILED_MESSAGE_REPORT_FAILURE,
              payload:error
            });
          })
      }
}
