/* eslint-disable */
import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { Grid } from '@mui/material'

import * as Styled from '../components/styled-components/Campaign';
import appConfig from '../config/appConfig';


const FormDialog = (props) => {

    const{title,primaryAction,secondaryAction,secondaryText,PrimaryText,children} = props;
   const buttonCss ={ mt: 3, ml: 1, fontFamily: appConfig.fontfamily, textTransform: 'capitalize', width: "100%", height: "50px" }

      return (
        <React.Fragment>
          <Dialog
            open={props.status}
            onClose={props.handleClose}
          >
            <span style={{padding:"15px 20px",fontSize:"24px"}}><b>{title}</b></span>
            <DialogContent>
             {children}
            </DialogContent>
            <DialogActions>
            <Grid container  style={{display:"flex",justifyContent:"flex-end"}}>
                    <Grid item xs={2} sm={2} xl={2} lg={2} md={2} >
            <Styled.CancelButton
                            variant="outlined"
                            sx={{buttonCss}}
                            color="primary"
                            onClick={secondaryAction}
                        >
                           {secondaryText}
                        </Styled.CancelButton>
                        </Grid>
                        <Grid item xs={2} sm={2} xl={2} lg={2} md={2}>
                        <Styled.CampaignButton
                            type='submit'
                            variant="contained"
                            sx={{buttonCss}}
                            color="primary"
                            onClick={primaryAction}
                        >
                            {PrimaryText}
                        </Styled.CampaignButton>
                        </Grid>
                        </Grid>
            </DialogActions>
          </Dialog>
        </React.Fragment>
      );
}

export default FormDialog;