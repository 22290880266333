import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { createStore,applyMiddleware  } from 'redux';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';

// Azure
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./config/config";

import allReducers from './store/reducers';
import App from './App';
import './styles/App.scss';


import ErrorBoundary from './components/ErrorBoundary';

const store = createStore(allReducers,applyMiddleware(thunk));

// if (window.location.pathname === '/') {
//   window.location.href='/clientcomm';
// }

// Azure
const msalInstance = new PublicClientApplication(msalConfig);

ReactDOM.render(
    <Provider store={store}>
      <BrowserRouter >
      <ErrorBoundary>
      <MsalProvider instance={msalInstance}>
      <App/>
      </MsalProvider>
      </ErrorBoundary>
      </BrowserRouter>
    </Provider>,
  document.getElementById('root')
);
