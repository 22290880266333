import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Grid } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import { endOfDay, startOfDay } from 'date-fns'
import moment from 'moment';
import TableCell from '@mui/material/TableCell';
import Swal from "sweetalert2";

import SkeletonGridLoader from '../components/SkeletonGridLoader';

import DefaultTable from '../components/Table';
import appConfig from '../config/appConfig';
import SpinningLoader from '../components/SpinningLoader';


import * as reconcileActions from '../store/actions/reconcile';

import { CalculateUTCTzToUserTz, CalculateUserTzToUTCTz } from '../components/Utils';


const Reconcile = ({ reconcileData, fetchReconcileList, reconcileFile, regenerateFile }) => {

  const [pagination, setPagination] = useState({ limit: 10, offset: 1 });
  const [selectedDate, setSelectedDate] = useState({
    startDate: startOfDay(new Date()),
    endDate: endOfDay(new Date())
  });
  const [channel, setChannel] = useState({ key: "SMS", value: "sms" });

  const [searchText, setSearchText] = useState("");
  const isMounted = useRef(false);
  const [isReconcile, setIsReconcile] = useState(false);
  const [isRegenerate, setIsRegenerate] = useState(false);


  const handlePagination = (limit, offset) => {
    setPagination({ limit, offset })
  }

  const onDateChange = (date) => {
    setSelectedDate({
      startDate: date?.[0]?.startDate || date?.startDate,
      endDate: date?.[0]?.endDate || date?.endDate
    });
  }

  const handleFilterChange = (filter) => {
    setSearchText("");
    setChannel(filter);
  }

  const handleSearch = (search) => {
    isMounted.current = false;
    setSearchText(search);
  }

  const handleReconcile = (fileId, chl) => {
    const swalWithCustompButtons = Swal.mixin({
      customClass: {
        confirmButton: 'custom-ok-button',
        cancelButton: 'custom-cancel-button'
      },
      buttonsStyling: false
    })
    swalWithCustompButtons.fire({
      title: "",
      text: 'Reconcile File?',
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'Cancel',
      confirmButtonText: 'Reconcile',
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
        reconcileFile(fileId, chl);
        setIsReconcile(true);
      }
    })
  }

  const handleRegenerate = (fileId, chl) => {
    const swalWithCustompButtons = Swal.mixin({
      customClass: {
        confirmButton: 'custom-ok-button',
        cancelButton: 'custom-cancel-button'
      },
      buttonsStyling: false
    })
    swalWithCustompButtons.fire({
      title: "",
      text: 'Regenerate File?',
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'Cancel',
      confirmButtonText: 'Regenerate',
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
        regenerateFile(fileId, chl);
        setIsRegenerate(true);
      }
    })
  }

  const filterValues = {
    filterBy: channel.value,
    startDate: CalculateUserTzToUTCTz(selectedDate?.startDate).toISOString(),
    endDate: CalculateUserTzToUTCTz(endOfDay(selectedDate?.endDate)).toISOString()
  }

  const renderCells = (row) => {
    return <>
      <TableCell sx={{ fontFamily: appConfig.fontfamily, fontSize: appConfig.font16 }}>{row.originalFileName}</TableCell>
      <TableCell sx={{ fontFamily: appConfig.fontfamily, fontSize: appConfig.font16 }}>{row?.statusDetails?.total ? row?.statusDetails?.total : '-'}</TableCell>
      <TableCell sx={{ fontFamily: appConfig.fontfamily, fontSize: appConfig.font16 }}>{row?.statusDetails?.Queued ? row?.statusDetails?.Queued : '-'}</TableCell>
      <TableCell sx={{ fontFamily: appConfig.fontfamily, fontSize: appConfig.font16 }}>{row?.statusDetails?.Sent ? row?.statusDetails?.Sent : '-'}</TableCell>
      <TableCell sx={{ fontFamily: appConfig.fontfamily, fontSize: appConfig.font16 }}>{row?.statusDetails?.Delivered ? row?.statusDetails?.Delivered : '-'}</TableCell>
      <TableCell sx={{ fontFamily: appConfig.fontfamily, fontSize: appConfig.font16 }}>{row?.statusDetails?.Undelivered ? row?.statusDetails?.Undelivered : '-'}</TableCell>
      <TableCell sx={{ fontFamily: appConfig.fontfamily, fontSize: appConfig.font16 }}>{row?.statusDetails?.Failed ? row?.statusDetails?.Failed : '-'}</TableCell>
      <TableCell sx={{ fontFamily: appConfig.fontfamily, fontSize: appConfig.font16 }}>{row?.statusDetails?.Invalid ? row?.statusDetails?.Invalid : '-'}</TableCell>

    </>
  }

  /* eslint-disable */
  const renderActionButton = (row) => {
    return <div>
      {row.reconcileStatus === 'Processing' ? <span style={{ fontFamily: appConfig.fontfamily, fontSize: appConfig.font16 }}>In Progress</span> :
        <a href="javascript:void(0)" style={{ color: appConfig.colors.blueprimary, fontSize: appConfig.font16 }} onClick={() => handleReconcile(row.id, channel.value)} >Reconcile</a>}
      {row.reconcileStatus === 'Processing' ? null : <a className='mar-l-10' href="javascript:void(0)" style={{ color: appConfig.colors.blueprimary, fontSize: appConfig.font16 }} onClick={() => handleRegenerate(row.id, channel.value)} >Regenerate</a>}
    </div>
  }


  useEffect(() => {
    if (reconcileData?.reconcileFile?.data?.status === 1 && isReconcile) {
      const swalWithCustompButtons = Swal.mixin({
        customClass: {
          confirmButton: 'custom-ok-button',
        },
        buttonsStyling: false
      })
      swalWithCustompButtons.fire({
        text: "Reconcile is currently progress.",
        icon: "success",
        confirmButtonText: 'OK',
      }).then((res) => {
        if (res.isConfirmed) {
          fetchReconcileList(filterValues, searchText, pagination.limit, pagination.offset);
          setIsReconcile(false);
        }
      })
    } else if (reconcileData?.reconcileFile?.data?.status === 0 && isReconcile) {
      Swal.fire({
        icon: "warning",
        button: false,
        showConfirmButton: false,
        timer: 2000,
        text: reconcileData?.reconcileFile?.data?.message,
      });
      setIsReconcile(false);
    } else if (reconcileData?.regenerateFile?.data?.status === 1 && isRegenerate) {
      const swalWithCustompButtons = Swal.mixin({
        customClass: {
          confirmButton: 'custom-ok-button',
        },
        buttonsStyling: false
      })

      swalWithCustompButtons.fire({
        text: "Regeneration is currently progress.",
        icon: "success",
        confirmButtonText: 'OK',
      }).then((result) => {
        if (result.isConfirmed) {
          fetchReconcileList(filterValues, searchText, pagination.limit, pagination.offset);
          setIsRegenerate(false);
        }
      })
    } else if (reconcileData?.regenerateFile?.data?.status === 0 && isRegenerate) {
      Swal.fire({
        icon: "warning",
        button: false,
        showConfirmButton: false,
        timer: 2000,
        text: reconcileData?.regenerateFile?.data?.message,
      });
      setIsRegenerate(false);
    }

  }, [JSON.stringify(reconcileData?.reconcileFile), JSON.stringify(reconcileData?.regenerateFile)])




  useEffect(() => {
    if (!isMounted.current) {
      setPagination({ limit: pagination.limit, offset: 1 })
      fetchReconcileList(filterValues, searchText, pagination.limit, pagination.offset);
    }
  }, [searchText])


  useEffect(() => {
    isMounted.current = true;
    fetchReconcileList(filterValues, searchText, pagination.limit, pagination.offset);
  }, [JSON.stringify(pagination), channel, JSON.stringify(selectedDate)])


  return <div>
    <Grid container spacing={2}>
    {(reconcileData?.reconcileFile?.isLoading || reconcileData?.regenerateFile?.isLoading) ? <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={reconcileData?.reconcileFile?.isLoading||reconcileData?.regenerateFile?.isLoading}
        >
            <SpinningLoader circleColor={appConfig.colors.white} label="Loading" />
        </Backdrop> :  null }
      <Grid item xs={6} sm={6} xl={6} lg={6} md={6}>
        <h1 className='page-heading' ><b>Status Reconciliation</b></h1>
      </Grid>
      <Grid item xs={12} sm={12} xl={12} lg={12} md={12}>
        <hr className='blue-border' />
      </Grid>
    </Grid>

    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} xl={12} lg={12} md={12}>
        {reconcileData?.reconcileList?.isLoading ? <SkeletonGridLoader /> :
          <DefaultTable
            title="Total"
            handlePagination={handlePagination}
            // headers={['Filename', 'Total Records', 'Queued', 'Sent', 'Delivered', 'Undelivered', 'Failed']}
            headers={[{name:'Filename', sortEnabled:false},{name:'Total Records', sortEnabled:false},{name:'Queued', sortEnabled:false},{name:'Sent', sortEnabled:false},{name:'Delivered', sortEnabled:false},{name:'Undelivered', sortEnabled:false},{name:'Failed', sortEnabled:false},{name:'Invalid', sortEnabled:false}]}

            data={reconcileData?.reconcileList?.data?.files}
            totalCount={reconcileData?.reconcileList?.data?.totalCount}
            currentPage={pagination.offset}
            currentLimit={pagination.limit}
            isDateFilter
            selectedDateObj={(date) => onDateChange(date)}
            dateFilterLabel={`${moment(CalculateUTCTzToUserTz(selectedDate.startDate)).format('MM-DD-YYYY')} - ${moment(CalculateUTCTzToUserTz(selectedDate.endDate)).format('MM-DD-YYYY')}`}
            filterOptions={[{ key: "SMS", value: "sms" }, { key: "Email", value: "email" }]}
            isFilterEnabled
            filterAction={(filter) => { handleFilterChange(filter) }}
            defaultFilter={channel}
            isSearchEnabled
            searchCallback={(text) => handleSearch(text)}
            searchKey={searchText}
            searchPlaceholderText={'Search By Filename'}
            renderCustomCells
            renderCells={renderCells}
            hasAction
            renderActionButton={renderActionButton}
            isRefreshEnabled
            refreshCallback={() => { fetchReconcileList(filterValues, searchText, pagination.limit, pagination.offset) }}
          />}
      </Grid>
    </Grid>

  </div>
}


Reconcile.propTypes = {
  reconcileData: PropTypes.objectOf(PropTypes.object).isRequired,
  fetchReconcileList: PropTypes.func.isRequired,
  reconcileFile: PropTypes.func.isRequired,
  regenerateFile: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({ reconcileData: state.reconcile })

const mapDispatchToProps = (dispatch) => {
  return {
    fetchReconcileList: (data, search, limit, page) => dispatch(reconcileActions.fetchReconcileList(data, search, limit, page)),
    reconcileFile: (fileId, channel) => dispatch(reconcileActions.fetchReconcileStatus(fileId, channel)),
    regenerateFile: (fileId, channel) => dispatch(reconcileActions.regenerateFile(fileId, channel)),
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(Reconcile);


