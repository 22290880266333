import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import CampaignOutlinedIcon from '@mui/icons-material/CampaignOutlined';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import BackupTableIcon from '@mui/icons-material/BackupTable';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import PanToolOutlinedIcon from '@mui/icons-material/PanToolOutlined';
import ScreenSearchDesktopOutlinedIcon from '@mui/icons-material/ScreenSearchDesktopOutlined';
import CloudSyncOutlinedIcon from '@mui/icons-material/CloudSyncOutlined';
import SpeakerNotesOffOutlinedIcon from '@mui/icons-material/SpeakerNotesOffOutlined';
import { hasAccess } from '../components/Utils';


const hasCampaignAccess = hasAccess( 'CAM');
const hasTemplateAccess = hasAccess('TEM');
const hasOptOutAccess = hasAccess('OPT');
const hasUserAccess = hasAccess('USR');
const hasLogAccess = hasAccess('LOG');
const hasReportAccess = hasAccess('RPT');
const hasReconcileAccess = hasAccess('REC');
const hasInvalidReportAccess = hasAccess('INV');
const hasInstantCampaignAccess = hasAccess('INC');
const hasSettingsAccess = hasAccess('SET');
const hasInboundAccess = hasAccess('ADH');
const hasOutboundAccess = hasAccess('EXT');
const hasBillingAccess = hasAccess('BIL');


const SideMenuItems = [
    {
        name: "Dashboard",
        route: "dashboard",
        icon: DashboardOutlinedIcon,
        isSubMenu:false,
        isBusRoute:true,
        isAdminRoute:true,
        isPRAdminRoute:true,
        subRoutes:[''],
        isShow:true
    },
    {
        name: "Campaigns",
        route: "campaign",
        subRoutes: ["campaigndetails","createcampaign", 'uploadfile','campaignfiles'],
        icon: CampaignOutlinedIcon,
        isBusRoute: hasCampaignAccess,
        isPRAdminRoute:hasCampaignAccess,
        isAdminRoute: hasCampaignAccess,
        isShow:true
    },
    {
        name: "Templates",
        route: "templates",
        icon: BackupTableIcon,
        subRoutes:["createtemplate","edittemplate"],
        isBusRoute:hasTemplateAccess,
        isPRAdminRoute:hasTemplateAccess,
        isAdminRoute:hasTemplateAccess,
        isShow:true
    },
    {
        name: "Instant Notifications",
        route: "instant",
        subRoutes: ["staffalerts"],
        icon: CampaignOutlinedIcon,
        isBusRoute:hasInstantCampaignAccess,
        isPRAdminRoute:hasInstantCampaignAccess,
        isAdminRoute:hasInstantCampaignAccess,
        isShow:true
    },
    {
        name: "Reports",
        route: "reports",
        icon: QueryStatsIcon,
        isAdminRoute:hasReportAccess,
        isBusRoute:hasReportAccess,
        isPRAdminRoute:hasReportAccess,
        subRoutes:[],
        isShow:true
    },
    {
        name: "Error Log",
        route: "errorlog",
        icon: SpeakerNotesOffOutlinedIcon,
        isAdminRoute:hasInvalidReportAccess,
        isBusRoute:hasInvalidReportAccess,
        isPRAdminRoute:hasInvalidReportAccess,
        subRoutes:[],
        isShow:true
    },
    {
        name: "Status Reconciliation",
        route: "reconcile",
        icon: CloudSyncOutlinedIcon,
        isSubMenu:false,
        isAdminRoute:hasReconcileAccess,
        isPRAdminRoute:hasReconcileAccess,
        subRoutes:[],
        isShow:true
    },
    {
        name: "Opt Out",
        route: "optoutlist",
        icon: PanToolOutlinedIcon,
        isAdminRoute:hasOptOutAccess,
        isBusRoute:hasOptOutAccess,
        isPRAdminRoute:hasOptOutAccess,
        subRoutes:[],
        isShow:true
    },
    {
        name: "Inbound API Logs",
        route: "adhoclogs",
        icon: PanToolOutlinedIcon,
        isAdminRoute:hasInboundAccess,
        isBusRoute:hasInboundAccess,
        isPRAdminRoute:hasInboundAccess,
        subRoutes:[],
        isShow:true
    },
    {
        name: "Outbound API Logs",
        route: "externallogs",
        icon: PanToolOutlinedIcon,
        isAdminRoute:hasOutboundAccess,
        isBusRoute:hasOutboundAccess,
        isPRAdminRoute:hasOutboundAccess,
        subRoutes:[],
        isShow:true
    },
    {
        name: "Billing",
        route: "billing",
        icon: SettingsOutlinedIcon,
        isSubMenu:false,
        isAdminRoute:hasBillingAccess,
        isPRAdminRoute:hasBillingAccess,
        subRoutes:[],
        isShow:true
    },
    {
        name: "User Management",
        route: "usermanagement",
        icon: GroupOutlinedIcon,
        isSubMenu:true,
        isAdminRoute:hasUserAccess,
        isPRAdminRoute:hasUserAccess,
        subRoutes: ["createuser"],
        isShow:true
    },
    {
        name: "Email Configuration",
        route: "emailconfiguration",
        icon: SettingsOutlinedIcon,
        isSubMenu:false,
        isAdminRoute:hasSettingsAccess,
        isPRAdminRoute:hasSettingsAccess,
        subRoutes:[],
        isShow:true
    },
    {
        name: "Audit Trail",
        route: "audits",
        icon: ScreenSearchDesktopOutlinedIcon,
        isSubMenu:false,
        isAdminRoute:hasLogAccess,
        isPRAdminRoute:hasLogAccess,
        subRoutes:[],
        isShow:true
    },
    {
        name: "Glossary",
        route: "glossary",
        icon: ScreenSearchDesktopOutlinedIcon,
        isSubMenu:false,
        isAdminRoute:true,
        isBusRoute:true,
        isPRAdminRoute:true,
        subRoutes:[],
        isShow:true
    }

];

export default SideMenuItems;