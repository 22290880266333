import moment from 'moment';
import momentTz from 'moment-timezone';

export function CalculateUserTzToUTCTz(dtVal) {
    const dateTime = moment(dtVal).format('YYYY-MM-DD HH:mm:ss');
    const browserTz = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const userTZ =  momentTz.tz(dateTime, browserTz);
    const UTCTz =  userTZ.clone().tz("Etc/GMT");
    return UTCTz;
}

export function CalculateUTCTzToUserTz(dtVal) {
    const dateTime = dtVal; 
    if(dateTime){
        const browserTimezone = moment(dateTime).local().format('MM/DD/YYYY HH:mm');
        return browserTimezone;
    }
    return '-';
}


export function isValidUSZip(sZip) {
    return /^[1-9]\d*(\.\d+)?$/.test(sZip);
}

export function retriveLanguage(code) {

    const language = {
        en: "English (en)",
        pt: "Portuguese (pt)",
        vi: "Vietnamese (vi)",
        es: "Spanish (es)",
        zh: "Chinese (zh)",
        ht: "Haitian Creole (ht)",
    }
    return language[code];
}

export function splitDate(dtVal) {
    const dateTime = dtVal; 
    if(dateTime){
        const browserTimezone = moment(dateTime).local().format('MM/DD/YYYY HH:mm');
        if(browserTimezone !== "Invalid date"){
            const sDate = browserTimezone.split(" ");
            return sDate[0] ;
            
        }
    }
    return '-';
}

export function formatDTADate(unFormattedDate) {

    const monthJson = {
        "01": "Jan",
        "02": "Feb",
        "03": "Mar",
        "04": "Apr",
        "05": "May",
        "06": "Jun",
        "07": "Jul",
        "08": "Aug",
        "09": "Sep",
        "10": "Oct",
        "11": "Nov",
        "12": "Dec"
    }
    const spilttedDate = unFormattedDate.split('/');
    if(spilttedDate.length === 3){
        const month = monthJson[spilttedDate[0]];
        const day = spilttedDate[1];
        const year = spilttedDate[2];
        return `${day}.${month}.${year}`
    }
        return '-'
}

export function formatDTADateForPicker(unFormattedDate) {

    const monthJson = {
        "01": "Jan",
        "02": "Feb",
        "03": "Mar",
        "04": "Apr",
        "05": "May",
        "06": "Jun",
        "07": "Jul",
        "08": "Aug",
        "09": "Sep",
        "10": "Oct",
        "11": "Nov",
        "12": "Dec"
    }
    const spilttedDate = unFormattedDate.split('-');
    if(spilttedDate.length === 3){
        const month = monthJson[spilttedDate[0]];
        const day = spilttedDate[1];
        const year = spilttedDate[2];
        return `${day}.${month}.${year}`
    }
        return '-'
}

export function splitTime(dtVal) {
    const dateTime = dtVal; 
    if(dateTime){
            const browserTimezone = moment(dateTime).local().format('MM/DD/YYYY hh:mm a');
            if(browserTimezone !== "Invalid date"){
                const sDate = browserTimezone.split(" ");
                if(sDate){
                    return `${sDate[1]} ${sDate[2]?.toUpperCase() || "-"}`;
                }
                    return  "-";
            }
    }
    return '-';
}


export function getYearMonthFromDate(dtVal) {
    const monthJson = {
        "01": "Jan",
        "02": "Feb",
        "03": "Mar",
        "04": "Apr",
        "05": "May",
        "06": "Jun",
        "07": "Jul",
        "08": "Aug",
        "09": "Sep",
        "10": "Oct",
        "11": "Nov",
        "12": "Dec"
    }
    const spilttedDate = dtVal.split('/');
    if(spilttedDate.length === 3){
        const month = monthJson[spilttedDate[0]];
        const year = spilttedDate[2];
        return `${month}.${year}`
    }
        return '-'
}


export const getChannelLabel = (channel) =>{
    return channel.toLowerCase() === "email" ? "Email" : "SMS";
}

export const getCampaignStatus = (code) => {
    let status = ""
    switch (code) {
      case 307:
        status = 'Completed'
        break;
      case 306:
        status = 'Processed'
        break;
      case 305:
        status = 'Processed'
        break;
      case 304:
        status = 'Cancelled'
        break;
      case 303:
        status = 'Failed'
        break;
      case 302:
        status = 'Scheduled'
        break;
      case 301:
        status = 'Processing'
        break;
      default:
        status = '-'
        break;
    }
    return status;
  }

  export const trimStringSpace = (str) =>{
    return str.replace(/\s+/g,' ').trim();
}



export const containsSpecialCharacters = (str) => {
    // eslint-disable-next-line
    const specialChars = /[`!#$%^&*()_+\-=\[\]{};':"\\|,<>\/?~]/;
    return specialChars.test(str);
  }

  export const containsSpecialCharactersGrid = (str) => {
    // eslint-disable-next-line
    const specialChars = /[`!#$%^&*()+\-=\[\]{};':"\\|,<>\/?~]/;
    return specialChars.test(str);
  }


  export const calculateDaysInDates = (startDate,endDate) => {
    const diffTime = Math.abs(startDate - endDate);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays
  }

  export const hasAccess = (code) => {
    const auth = JSON.parse(window.sessionStorage.getItem('userInfo'));
    return (
      auth &&
      auth.authorities &&
      auth.authorities.some((authCode) => authCode.includes(code))
    );
  }

  export const isRendermenu = () =>{
    const list = ["MassHealth"]
    const tenant =  sessionStorage.getItem('tenantID');
    return list.includes(tenant);

  }
  