import * as actionTypes from '../actions/actionTypes';


const initialState = {
   reconcileList:{
    isLoading: false,
    isError: false,
    data:{},
   },
   reconcileFile:{
    isLoading: false,
    isError: false,
    data:{},
   },
   regenerateFile:{
    isLoading: false,
    isError: false,
    data:{},
   }
  }

  const reconcileReducer = (state = initialState, action = {}) => {

    const newState = { ...state };
  
    switch (action.type) {
      case actionTypes.FETCH_RECONCILE_LIST:
        return { ...newState, reconcileList: { ...newState.reconcileList, isLoading: true } };
  
      case actionTypes.FETCH_RECONCILE_LIST_SUCCESS:
        return { ...newState, reconcileList: {data:{...action.payload},isLoading: false, isError: false} };

      case actionTypes.FETCH_RECONCILE_LIST_FAILURE:
        return { ...newState, reconcileList: {data:{...action.payload},isLoading: false, isError: true} };

        case actionTypes.FETCH_RECONCILE_STATUS:
            return { ...newState, reconcileFile: {isLoading: true, data:{}} };
      
          case actionTypes.FETCH_RECONCILE_STATUS_SUCCESS:
            return { ...newState, reconcileFile: {data:{...action.payload},isLoading: false, isError: false} };
    
          case actionTypes.FETCH_RECONCILE_STATUS_FAILURE:
            return { ...newState, reconcileFile: {data:{...action.payload},isLoading: false, isError: true} };

            case actionTypes.REGENERATE_STATUS:
              return { ...newState, regenerateFile: { isLoading: true, data:{} } };
        
            case actionTypes.REGENERATE_STATUS_SUCCESS:
              return { ...newState, regenerateFile: {data:{...action.payload},isLoading: false, isError: false} };
      
            case actionTypes.REGENERATE_STATUS_FAILURE:
              return { ...newState, regenerateFile: {data:{...action.payload},isLoading: false, isError: true} };
    

      default:
        return { ...newState };
    }
  };
  
  
  export default reconcileReducer;